<template>
  <div>
   
 
 
        <!--内部步骤条-->
        <el-steps :active="active" finish-status="success"  style="margin-left: 15px; margin-bottom: 15px">
          <el-step title="企业基本信息"></el-step>
          <el-step title="联系信息"></el-step>
          <el-step title="特殊资质与认证"></el-step>
          <el-step title="其他信息"></el-step>
          <el-step title="账户信息"></el-step>
        </el-steps>
 
      
        <!-- 内部委托单 -->
        <el-form ref="form" :model='form' :rules='rules' label-width="150px" >
          <!--step1-->
          <div v-show="active == 1">
            <el-row >
              <el-col :span="12">
                <el-form-item label="企业名称" prop="companyName">
                  <el-input v-model="form.companyName" placeholder="请输入企业名称" /> 
                </el-form-item>
              </el-col >
              <el-col :span="12">
                <el-form-item label="企业类型" prop="companyType">
                  <el-select v-model="form.companyType" placeholder="请选择企业类型">
                    <el-option
                      v-for="dict in dictCompanyType"
                      :key="dict.value"
                      :label="dict.label"
                      :value="(dict.value)"
                    ></el-option>
                  </el-select> 
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="注册地址" prop="address">
                  <el-input v-model="form.address" placeholder="请输入注册地址" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="法定代表人" prop="legal">
                  <el-input v-model="form.legal" placeholder="请输入法定代表人" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="注册资本" prop="regCapital">
                  <el-input v-model="form.regCapital" placeholder="如：货币出资、实物出资等" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="统一社会信用代码" prop="code">
                  <el-input v-model="form.code" placeholder="请输入统一社会信用代码" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="经营范围" prop="bscope">
                  <el-input v-model="form.bscope" type="textarea" placeholder="应链管理、食品销售（需明确是否包含预包装食品、散装食品等）、食用农产品批发零售、电子商务等" />
                </el-form-item>
                
              </el-col>
              <el-col :span="12">
                 
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="法人身份证正面" prop="fPic">
                  <el-upload
                  drag
                  ref="upload1"
                  :limit="limit"
                  list-type="picture"
                  :on-success="uploadSuccess"
                  :action="action"
                  :headers="{Authorization:getToken()}"
                  multiple>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将法人身份证正面拖到此处，或<em>点击上传</em></div>
                </el-upload> 
              </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="法人身份证反面" prop="yPic">
                  <el-upload
                  drag
                  ref="upload11"
                  :limit="limit"
                  list-type="picture"
                  :on-success="uploadSuccess"
                  :action="action"
                  :headers="{Authorization:getToken()}"
                  multiple>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将法人身份证反面拖到此处，或<em>点击上传</em></div>
                </el-upload> 
                </el-form-item>
              </el-col>
            </el-row>

           
         
          </div>
          <!--step2-->
          <div v-show="active == 2">
            <el-row>
              <el-col :span="12">
                <el-form-item label="联系人" prop="concatP">
                  <el-input v-model="form.concatP" placeholder="请输入联系人" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="联系电话" prop="concatT">
                  <el-input v-model="form.concatT" placeholder="请输入联系电话" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="电子邮箱"    prop="concatE">
                  <el-input v-model="form.concatE" placeholder="请输入电子邮箱" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="通讯地址" prop="concatA">
                  <el-input v-model="form.concatA" placeholder="请输入通讯地址" />
                </el-form-item>
              </el-col>
            </el-row>

           
            <el-row>
              <el-col :span="12">
                <el-form-item label="营业执照正面" prop="fPic">
                  <el-upload
                  drag
                  ref="upload2"
                  :limit="limit"
                  list-type="picture"
                  :on-success="uploadSuccess"
                  :action="action"
                  :headers="{Authorization:getToken()}"
                  multiple>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将营业执照正面拖到此处，或<em>点击上传</em></div>
                </el-upload> 
              </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="营业执照反面" prop="yPic">
                  <el-upload
                  drag
                  ref="upload22"
                  :limit="limit"
                  list-type="picture"
                  :on-success="uploadSuccess"
                  :action="action"
                  :headers="{Authorization:getToken()}"
                  multiple>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将营业执照反面拖到此处，或<em>点击上传</em></div>
                </el-upload> 
                </el-form-item>
              </el-col>
            </el-row>

          </div>
          <!--step3-->
          <div v-show="active == 3">
            <el-row>
              <el-col :span="12">
                <el-form-item label="食品经营许可证" prop="sPic">
                  <el-upload
                  drag
                  ref="upload3"
                  :limit="limit"
                  list-type="picture"
                  :on-success="uploadSuccess"
                  :action="action"
                  :headers="{Authorization:getToken()}"
                  multiple>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将食品经营许可证拖到此处，或<em>点击上传</em></div>
                </el-upload> 
                </el-form-item>
              </el-col>
              <el-col :span="12">

                <el-form-item label="质量管理体系认证" prop="zPic">
                  <el-upload
                  drag
                  ref="upload4"
                  :limit="limit"
                  list-type="picture"
                  :on-success="uploadSuccess"
                  :action="action"
                  :headers="{Authorization:getToken()}"
                  multiple>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将质量管理体系认证拖到此处，或<em>点击上传</em></div>
                </el-upload> 
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="其他相关认证" prop="oPic">
                  <el-upload
                  drag
                  ref="upload5"
                  :limit="limit"
                  list-type="picture"
                  :on-success="uploadSuccess"
                  :action="action"
                  :headers="{Authorization:getToken()}"
                  multiple>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                </el-upload> 
                </el-form-item>
              </el-col>
              <el-col :span="12">
                 
              </el-col>
            </el-row>

            
          </div>
          <!--step4-->
          <div v-show="active == 4">
          <el-row>
            <el-col :span="24">
              <el-form-item label="品牌标识" label-width="80px">
                <el-input v-model="form.flag" type="textarea" placeholder="品牌标识" />
              </el-form-item>
             
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="企业简介"   label-width="80px">
                <editor ref='noticeContentEditor' v-model='form.desc' />
              </el-form-item>
            </el-col>

          </el-row>
          </div>
          

          <!--step5-->
          <!-- style="display: flex; justify-content: center; align-items: center; height: 100px;" 居中 -->
          <!-- prop='username' -->
          <!-- prop='password'  -->
          <!-- prop='confirmPassword'  -->
          <div v-show="active == 5">

            <el-row>
            <el-col :span="5" >
              <el-form-item label="溯源类型"  prop='pid' label-width="80px"> 
            <a-tree-select
              v-model='form.pid'
              style='width: 200px'
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              :tree-data='treeOptions'
              placeholder='请选择'
              :replaceFields='replaceFields'
              tree-default-expand-all
            >
            </a-tree-select>
          </el-form-item>
             
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="5" >
              <el-form-item label="用户名"  prop='username' label-width="80px">  
                <a-input v-model='form.username' size='large' autocomplete='off' placeholder='账户' >
           <a-icon slot='prefix' type='user' :style="{ color: 'rgba(0,0,0,.25)' }" />
        </a-input>
              </el-form-item>
             
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="5">
              <el-form-item label="密码" prop='password' label-width="80px">
                <a-input-password v-model='form.password' size='large' autocomplete='off' placeholder='密码' :maxLength='20' >
           <a-icon slot='prefix' type='lock' :style="{ color: 'rgba(0,0,0,.25)' }" />
        </a-input-password>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="5">
              <el-form-item label="确认密码"  prop='confirmPassword' label-width="80px">
                <a-input-password v-model='form.confirmPassword' size='large' autocomplete='off' placeholder='确认密码'
                          :maxLength='20' >
                           <a-icon slot='prefix' type='lock' :style="{ color: 'rgba(0,0,0,.25)' }" />
                        </a-input-password>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="5"  >
              <el-form-item label="验证码"   label-width="80px">
                <a-input v-model='form.code' size='large' type='text' autocomplete='off' placeholder='验证码'>
              <a-icon slot='prefix' type='security-scan' :style="{ color: 'rgba(0,0,0,.25)' }" />
            </a-input>
              </el-form-item>
            </el-col>
            <el-col class='gutter-row' :span='8'>
                  <img class='getCaptcha' :src='codeUrl' @click='getCode'>
              </el-col>
          </el-row>
          </div>
        </el-form>
        <!--内部步骤按钮-->
       
      <div slot="footer" class="dialog-footer">
        <div v-if="formShowin">
        <el-button type="primary" plain v-if="active > 1" style="margin-top: 15px; margin-left: 15px;" @click="pre">上一步</el-button>
        <el-button type="primary" plain v-if="active < 5" style="margin-top: 15px; margin-left: 15px;" @click="next">下一步</el-button>
        <el-button type="primary" v-if="active==5" @click="handleRegister" >确 定</el-button>
        <el-button @click="go">取 消</el-button>
        </div>
      </div>
  </div>
</template>

<script>
import { getCodeImg, register } from '@/api/login'
import { treeList } from '@/api/traceability/index'
import Editor from '@/components/Editor'

export default {
  name: 'Register',
  components: {Editor},
  data() {
    const validateNewPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else if (!/^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$)([^\u4e00-\u9fa5\s]){5,20}$/.test(value)) {
        callback(new Error('请输入5-20位英文字母、数字或者符号（除空格），且字母、数字和标点符号至少包含两种'))
      } else {
        if (this.form.confirmPassword !== '') {
          this.$refs.form.validateField('confirmPassword')
        }
        callback()
      }
    }
    const validateConfirmPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新密码确认'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    }
    return {
      replaceFields: { children: 'children', title: 'name', key: 'id', value: 'id' },
      treeOptions: [{
        id: 0,
        label: '',
        children: []
      }],
      fileList:[],
      limit:2,
      action:process.env.VUE_APP_BASE_API + '/common/uploadNotoken',
      dictCompanyType:[{label:"有限责任公司",value:2},{label:"股份有限公司",value:1},{label:"个体",value:0}],
      formShowout:true,
      formShowin:true,
      open:true,
      active:1,
      title:'',
      codeUrl: '',
      isRegisterError: false,
      registerErrorInfo: '',
      form: {
        pid:undefined,
        username: undefined,
        password: undefined,
        confirmPassword: undefined,
        code: undefined,
        uuid: undefined,
        noticeContentEditor:undefined,
        noticeContentEditor2:undefined,
        companyName:undefined,

      },
      rules: {
        pid: [
          { required: true, trigger: 'blur', message: '请选择溯源类别' }
        ],
        companyName: [
          { required: true, trigger: 'blur', message: '请输入企业名称' }
        ],
        companyType: [
          { required: true, trigger: 'blur', message: '请选择企业类型' }
        ],
        address: [
          { required: true, trigger: 'blur', message: '请输入企业注册地址' }
        ],
        username: [
          { required: true, trigger: 'blur', message: '请输入您的账号' },
          { min: 2, max: 20, message: '用户账号长度必须介于 2 和 20 之间', trigger: 'blur' }
        ],
        password: [
          { required: true, trigger: 'blur', message: '请输入您的密码' },
          { required: true, validator: validateNewPass, trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, trigger: 'blur', message: '请再次输入您的密码' },
          { required: true, validator: validateConfirmPass, trigger: 'blur' }
        ],
        code: [{ required: true, trigger: 'change', message: '请输入验证码' }]
      },
      registerBtn: false,
      registering: false,
      captchaOnOff: true
    }
  },
  computed: {},
  created() {
    this.getCode()
    this.getTreeselect()
  },
  methods: {
    uploadSuccess(e){
        self.fileList = [{name: '表盘.jpg', url:  e.imgUrl}]
        //self.$refs.upload1.clearFiles();	//该方法就是清理上传列表
    },
    go(){
      this.$router.push({ path: '/login' })
    },
    getToken(){},
    closeDialog(){},
    /** 步骤条下一步的方法 */
    next() {
      if (this.active++ > 4) this.active = 1
    
    },
    /** 步骤条上一步的方法 */
    pre() {
      if (this.active-- < 2) this.active = 1
    },

    getCode() {
      getCodeImg().then((res) => {
        this.captchaOnOff = res.captchaOnOff === undefined ? true : res.captchaOnOff
        if (this.captchaOnOff) {
          this.codeUrl = 'data:image/gif;base64,' + res.img
          this.form.uuid = res.uuid
        }
      })
    },
        /** 查询品类下拉树结构 */
        getTreeselect() {
      treeList().then(response => {
        this.treeOptions = response.data
      })
    },
    handleRegister() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.registering = true

          let upload1 = this.$refs['upload1'].uploadFiles;
          this.form.fPic='';
          for(var i =0;i<upload1.length;i++){
            this.form.fPic+=upload1[i].response.url+',';
          }

          let upload11 = this.$refs['upload11'].uploadFiles;
          for(var i =0;i<upload11.length;i++){
            this.form.fPic+=upload11[i].response.url+',';
          }

          let upload2 = this.$refs['upload2'].uploadFiles;
          this.form.yPic='';
          for(var i =0;i<upload2.length;i++){
            this.form.yPic+=upload2[i].response.url+',';
          }

          let upload22 = this.$refs['upload22'].uploadFiles;
          for(var i =0;i<upload22.length;i++){
            this.form.yPic+=upload22[i].response.url+',';
          }

          let upload3 = this.$refs['upload3'].uploadFiles;
          this.form.sPic='';
          for(var i =0;i<upload3.length;i++){
            this.form.sPic+=upload3[i].response.url+',';
          }


          let upload4 = this.$refs['upload4'].uploadFiles;
          this.form.zPic='';
          for(var i =0;i<upload4.length;i++){
            this.form.zPic+=upload4[i].response.url+',';
          }

          let upload5 = this.$refs['upload5'].uploadFiles;
          this.form.oPic='';
          for(var i =0;i<upload5.length;i++){
            this.form.oPic+=upload5[i].response.url+',';
          }


          register(this.form)
            .then(res => this.registerSuccess(res))
            .catch(err => this.requestFailed(err))
            .finally(() => {
              this.registering = false
            })
        } else {
          setTimeout(() => {
            this.registering = false
          }, 600)
        }
      })
    },
    registerSuccess(res) {
      this.$router.push({ path: '/login' })
      // 延迟 1 秒显示欢迎信息
      const username = this.form.username
      setTimeout(() => {
        this.$notification.success({
          message: '恭喜你，您的账号 ' + username + ' 注册成功！'
        })
      }, 1000)
      this.handleCloseRegisterError()
    },
    requestFailed(err) {
      this.isRegisterError = true
      this.registerErrorInfo = err
      this.form.code = undefined
      if (this.captchaOnOff) {
        this.getCode()
      }
    },
    handleCloseRegisterError() {
      this.isRegisterError = false
      this.registerErrorInfo = ''
    }
  }
}
</script>
<style scoped>
.dialog-footer {
	position: sticky;
	bottom: 10px;
	text-align: center;
	background-color: #fff;
}
.getCaptcha {
  display: block;
  margin-left: 15px;
  width: 95px;
  height: 40px;
}

</style>